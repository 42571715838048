@import "standards/mySelectPopup.css";
.generalRoundedButton {
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 20px;
  font-size: 16px;
}
.primaryEffect {
  background: #0080FF;
  border: 0;
  color: white;
}
.primaryEffect:active {
  background: #1457C6;
  border: 0;
  color: white;
}
.redEffect {
  background: red;
  border: 0;
  color: white;
}
.redEffect:active {
  background: darkred;
  border: 0;
  color: white;
}
.greenEffect {
  background: green;
  border: 0;
  color: white;
}
.greenEffect:active {
  background: darkgreen;
  border: 0;
  color: white;
}
.primaryLightHoverEffect {
  background: white;
}
.primaryLightHoverEffect:active {
  background: #0080FF1A;
}
.greyLightHoverEffect {
  background: white;
}
.greyLightHoverEffect:active {
  background: #f0efef;
}
.myTextInput {
  border: 1px solid black;
  height: 35px;
  background: white;
  border-radius: 10px;
  padding-left: 8px;
}
.myDropDownInput {
  border: 1px solid black;
  height: 35px;
  background: white;
  border-radius: 10px;
  padding-left: 8px;
  width: 100%;
}
.myTeaxtAreaInput {
  border: 1px solid black;
  height: 60px;
  background: white;
  border-radius: 10px;
  padding-left: 8px;
}
.buttonProperties {
  font-size: 20px;
  position: relative;
  width: 95%;
  height: 50px;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
