body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noselect * {
  /*background: #282c34;*/
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/*.loginInputs * {*/
/*  !*font-size: 50px;*!*/
/*}*/

/*.loginInput {*/
/*  font-size: 40px;*/
/*}*/

/*.loginIcon {*/
/*  font-size: 50px;*/
/*}*/


.iframeMobilePreview {
  /*width: 420mm;*/
  /*height: 600mm;*/
  /*size: A4;*/
  zoom: 0.4;
  -moz-transform: scale(0.4);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.4);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.4);
  -webkit-transform-origin: 0 0;
}


.invoiceCard {
  /*background: white;*/
  background: #f7f7f7;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid lightgrey;
}

.invoiceCard:active {
  /*background: #c3efdf;*/
  background: #e3efef;
}

.navBar {
  background: white;
  border-bottom: 1px solid grey;
  font-weight: bold;
}

.selectedPartyTab {
  font-weight: bold;
  color: #01579b;
  width: 100%;
}

.unselectedPartyTab {
  font-weight: bold;
  color: #666;
  width: 100%;
}

.fab {
  position: absolute;
  bottom: 12px;
  right: 12px;
  box-shadow: 0px 0px 10px -3px #0080ff;
}


.adm-nav-bar-left {
  font-size: 40px;
}



.listItem {
  /*background: #0080FF;*/
}

.listItem:active {
  background: #e3f2fd;
}


.primaryEffectAbc {
  background: yellow;
}

.splash {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /*width: 100%;*/
  /*height: 100%;*/
  /*border: 10px solid green;*/
}
